import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/patient-local/patient-check"

export default {
	...commonApi(moduleName),
	getChecks: data =>
		request("/patient-local/patient-checks", {
			params: data
		}),
	// addRecord: data => request(`${moduleName}`, {
	//   method: 'post',
	//   body: data,
	// }),
	getCheckList: data =>
		request("/patient-local/patient-check-data-list", {
			params: data
		}),
	checkDataDeal: data =>
		request(`/patient-local/patient-check-data/${data.id}/deal`, {
			method: "post",
			body: data
		}),
	checkUse: data =>
		request(`${moduleName}/${data.id}/use`, {
			method: "post",
			body: data
		})
}
