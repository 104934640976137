<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<div v-show="!recordVisible">
			<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
				<CtmsSearchBoxItem :span="1" label="导入状态">
					<Select
						v-model="searchParams.ifUse"
						:transfer="true"
						:clearable="true"
						placeholder="请选择导入状态"
						@on-change="ifUseChange"
					>
						<Option value="1">已导入</Option>
						<Option value="0">未导入</Option>
					</Select>
				</CtmsSearchBoxItem>
			</CtmsSearchBox>
			<CtmsAction>
				<Upload
					ref="uploadcheck"
					:headers="{ token: $store.state.user.token }"
					:show-upload-list="false"
					accept=".xlsx"
					:before-upload="checkBeforeUpload"
					:on-success="onSuccess"
					:on-error="onError"
					:data="extraData"
					:action="`${$baseUrl}/patient-local/patient-check`"
					style="display: inline-block"
				>
					<Button>进行查重</Button>
				</Upload>
			</CtmsAction>
			<CtmsDataGrid
				:height="dataGridHeight"
				:columns="columns"
				:data="listData"
				:page="page"
			></CtmsDataGrid>
		</div>
		<Records
			v-show="recordVisible"
			:visible="recordVisible"
			@onCancel="recordVisible = false"
			@onOk="handleRecordOk"
			:recordId="recordId"
		></Records>
	</div>
</template>

<script>
import { mapState } from "vuex"
import { formDataToMD5 } from "@/utils/util"
import api from "@/api/subject/check"
import Records from "./Records.vue"

const { getChecks, apiDelete, checkUse } = api

export default {
	data() {
		return {
			loading: false,
			columns: [
				{
					title: "操作人",
					key: "updatedBy",
					minWidth: 160
				},
				{
					title: "操作时间",
					key: "updatedTime",
					minWidth: 160
				},
				{
					title: "重复受试者",
					minWidth: 160,
					key: "duplPatient"
				},
				{
					title: "重复项目经历",
					minWidth: 160,
					key: "duplPatientRecord"
				},
				{
					title: "待处理",
					minWidth: 100,
					key: "toDeal"
				},
				{
					title: "导入状态",
					key: "ifUse",
					minWidth: 80,
					render: (h, params) => {
						if (params.row.ifUse) {
							return h(
								"span",
								{
									style: {
										color: "#19be6b"
									}
								},
								"已导入"
							)
						}
						return h(
							"span",
							{
								style: {
									color: "#ed4014"
								}
							},
							"未导入"
						)
					}
				}
			],
			listData: [],
			actionColumn: [
				{
					title: "操作",
					key: "action",
					width: 250,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "查看记录",
								on: {
									click: this.viewRecords
								}
							},
							{
								label: "导入",
								on: {
									click: this.checkUseHandle
								}
							},
							{
								label: "删除",
								on: {
									click: this.deleteHandle
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]

						if (params.row.toDeal > 0 || params.row.ifUse) {
							actionList[1] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			extraData: {
				md5: ""
			},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			recordId: "",
			recordVisible: false,
			searchParams: {
				ifUse: ""
			},
			oldSearchParams: {}
		}
	},
	methods: {
		// 进行查重
		checkBeforeUpload(file) {
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.uploadcheck.post(file)
			})
			return false
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				this.$Message.success("上传成功!")
				this.initList()
			}
		},
		viewRecords({ row }) {
			this.recordVisible = true
			this.recordId = row.id
		},
		onError(error) {
			this.$Message.error(error)
		},
		ifUseChange(e) {
			this.searchParams.ifUse = e
			this.searchHandle()
			this.initList()
		},
		// 删除受试者
		deleteHandle({ row }) {
			this.$asyncDo(async () => {
				const res = await apiDelete({
					id: row.id
				})
				if (res) {
					this.$Message.success("删除成功")
					this.initList()
				}
			})
		},

		// 查看记录成功
		handleRecordOk() {
			this.recordVisible = false
			this.initList()
		},
		checkUseHandle({ row }) {
			this.$asyncDo(async () => {
				const res = await checkUse({
					id: row.id
				})
				if (res) {
					this.$Message.success("应用查重成功")
					this.initList()
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getChecks({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				ifUse: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		})
	},
	components: {
		Records
	},
	created() {
		this.columns = [...this.columns, ...this.actionColumn]
		this.initList()
	}
}
</script>
