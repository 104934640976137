<template>
	<div>
		<div v-show="!viewVisible">
			<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
				<CtmsSearchBoxItem :span="1" label="状态">
					<Select
						v-model="searchParams.ifAvl"
						:transfer="true"
						placeholder="请选择状态"
					>
						<Option value="0">待审查</Option>
						<Option value="1">可用</Option>
						<Option value="2">不可用</Option>
					</Select>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="查重结果">
					<Select
						v-model="searchParams.checkResult"
						:transfer="true"
						placeholder="请选择状态"
					>
						<Option value="0">无重复数据</Option>
						<Option value="1">受试者重复</Option>
						<Option value="2">项目重复</Option>
					</Select>
				</CtmsSearchBoxItem>
			</CtmsSearchBox>
			<CtmsAction>
				<Button @click="onOk" :loading="loading">返回</Button>
			</CtmsAction>
			<CtmsDataGrid
				:height="dataGridHeight"
				:columns="columns"
				:data="listData"
				:page="page"
			>
			</CtmsDataGrid>
		</div>
		<ViewData
			v-show="viewVisible"
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			@onOk="handleViewOk"
			:viewId="viewId"
			:viewRecords="viewRecords"
		></ViewData>
	</div>
</template>

<script>
import api from "@/api/subject/check"
import { mapState } from "vuex"
import ViewData from "@/views/Subject/viewData.vue"

const { getCheckList, checkDataDeal } = api

export default {
	props: ["visible", "recordId"],
	data() {
		return {
			viewVisible: false,
			viewId: "",
			columns: [
				{
					title: "筛查号",
					key: "screenNumber",
					minWidth: 100
				},
				{
					title: "姓名",
					key: "name",
					minWidth: 100
				},
				{
					title: "性别",
					key: "sex",
					minWidth: 80
				},
				{
					title: "身份证号",
					key: "idCardNo",
					minWidth: 180
				},
				{
					title: "参加过的项目（附编号）",
					key: "projectName",
					width: 300,
					nowrap: true
				},
				{
					title: "参加筛查时间",
					key: "screenTime",
					minWidth: 180
				},
				{
					title: "查重结果",
					key: "checkResult",
					minWidth: 160,
					render: (h, params) => {
						let txt = "无重复数据"
						if (params.row.checkResult) {
							txt = params.row.checkResult === 2 ? "项目重复" : "受试者重复"
						}
						return h("span", txt)
					}
				},
				{
					title: "状态",
					key: "ifAvl",
					minWidth: 100,
					render: (h, params) => {
						let txt = "待审查"
						let txtColor = "#2db7f5"
						if (params.row.ifAvl) {
							txt = params.row.ifAvl === 2 ? "不可用" : "可用"
							txtColor = params.row.ifAvl === 2 ? "#ed4014" : "#19be6b"
						}
						return h(
							"span",
							{
								style: {
									color: txtColor
								}
							},
							txt
						)
					}
				},
				{
					title: "操作",
					key: "action",
					width: 200,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "查看详情",
								on: {
									click: this.viewHandle
								}
							},
							{
								label: "可用",
								on: {
									click: () => {
										this.dealAbnormal(params, 1)
									}
								}
							},
							{
								label: "不可用",
								on: {
									click: () => {
										this.dealAbnormal(params, 2)
									}
								}
							}
						]
						if (params.row.ifAvl) {
							actionList[1] = null
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			listData: [],
			loading: false,
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: { ifAvl: "0", checkResult: "" },
			oldSearchParams: { ifAvl: "0" },
			viewRecords: {}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initList()
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$emit("onOk")
		},
		dealAbnormal(params, ifAvl) {
			this.$asyncDo(async () => {
				const res = await checkDataDeal({ id: params.row.id, ifAvl })
				if (res) {
					this.$Message.success("异常处理成功")
					this.initList()
				}
			})
		},
		// 查看详情
		viewHandle({ row }) {
			this.viewVisible = true
			this.viewRecords = row
			this.viewId = row.patientId
		},
		// 查看详情
		handleViewOk() {
			this.viewVisible = false
			this.initList()
		},
		initList() {
			this.$asyncDo(async () => {
				const res = await getCheckList({
					checkId: this.recordId,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					...this.oldSearchParams
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				ifAvl: "",
				checkResult: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		})
	},
	created() {},
	components: {
		ViewData
	}
}
</script>
